import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import HomePage from "../Pages/Home";
import Basemaps from "../Pages/Basemaps";
import Geocoding from "../Pages/Geocoding";
import Usage from "../Pages/Usage";
import MapServices from "../Pages/MapServices";
import Documentation from "../Pages/Documentation";
import Platform from "../Pages/Platform";
import DashBoard from "../Pages/DashBoard";
import Plan from "../Pages/Plan";
import Contact from "../Pages/Contact";
import Keys from "../Pages/Keys";
import Loader from "../Components/Areas/Loader";
import PrivateRoute from "./PrivateRoute";

export const AppRouter = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <Loader />;
  }

  return (
    <Router>
      <Routes>
        <Route path="" element={<HomePage />}>
          <Route path="/" element={<Platform />} />
          <Route path="/base-maps" element={<Basemaps />} />
          <Route path="/geocoding" element={<Geocoding />} />
          <Route path="/map-services" element={<MapServices />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route
            path="/Keys"
            element={
              <PrivateRoute>
                <Keys />
              </PrivateRoute>
            }
          />
     
          <Route
            path="/usage"
            element={
              <PrivateRoute>
                <Usage />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};
